<template>
  <div class="flex gap-5">
    <!-- liste des tickets du users -->
    <div class="w-4/12 sticky h-[70vh] overflow-auto top-24">
      <b-card>
        <b-card-text>
          <h6 class="text-indigo-500">
            Tickets: # <b>{{ tickets.code }}</b>
          </h6>
          <b-list-group>
            <b-list-group-item href="#" @click="content(tickets)">
              <b-avatar
                badge
                badge-variant="success"
                badge-offset="-0.5em"
                class="mr-1"
                size="sm"
              />
              <span>{{ tickets.libelle }}</span>
              <br />
              <span> <b>Service:</b> {{ tickets.service }} </span>
            </b-list-group-item>

            <hr />
            <h3>Tickets recents</h3>
            <b-list-group-item
              v-for="(item, index) in ticket_recent"
              :key="index.id"
              href="#"
            >
              <span @click="content_recent(item)">
                <b-avatar
                  badge
                  badge-variant="warning"
                  badge-offset="-0.5em"
                  class="mr-1"
                  size="sm"
                />
                <span>{{ item.libelle }}</span>
                <br />
                <span><b>Service</b> :{{ item.service }} </span>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
      </b-card>
    </div>

    <!-- liste des echanges-->
    <div class="flex flex-col w-8/12 py-2 relative">
      <div class="flex flex-col h-[70vh] overflow-auto">
        <div
          v-for="(item, index) in echanges"
          :key="index.id"
          class="flex flex-col"
        >
          <div
            :class="
              item.received_user === null
                ? 'flex-row justify-end'
                : 'flex-row justify-start'
            "
          >
            <div
              v-if="item.received_user === null"
              class="flex justify-content-start"
            >
              <b-avatar
                badge
                badge-variant=""
                badge-offset="-0.5em"
                class="mr-1"
                size="md"
              />
              <div
                class="bg-indigo-200 w-[50%] h-auto shadow-md rounded-lg text-black font-medium p-2 flex"
              >
                <span class="">
                  {{ item.description }}
                </span>
              </div>
            </div>

            <div
              v-if="item.received_user !== null"
              class="flex justify-content-end"
            >
              <b-avatar
                badge
                badge-variant=""
                badge-offset="-0.5em"
                class=""
                size="md"
                text="AD"
              />
              <div
                class="bg-gradient-to-r from-indigo-500 w-[50%] h-auto shadow-md rounded-lg text-white font-medium p-2"
              >
                <span class="">
                  {{ item.description }}
                </span>
              </div>
            </div>

            <div
              class="py-2"
              :class="
                item.received_user !== null
                  ? 'flex-col float-right'
                  : 'flex-col items-start'
              "
            >
              <img
                class="object-scale-down h-48 w-96"
                v-for="(item, index) in item.media"
                :key="index"
                style=""
                :src="item.original_url"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <b-form @submit.prevent class="pt-2">
        <!--  -->
        <div class="flex absolute bottom-0 bg-white w-full p-1">
          <!-- Write message -->
          <div
            class="flex justify-center items-center rounded-l w-full border-[0.5px] py-[4px] px-[16px]"
          >
            <input
              type="text"
              v-model="message"
              class="h-[24px] flex w-full outline-none"
              placeholder="Entrez votre message..."
            />

            <div class="">
              <label for="idImage" class="relative top-1">
                <feather-icon
                  icon="CameraIcon"
                  class="mx-auto h-6 w-6 opacity-50 text-purple-900 hover:opacity-100"
                />
              </label>
              <input
                class="hidden h-[0px] w-[0px]"
                @change="processFile($event)"
                multiple
                type="file"
                name=""
                id="idImage"
              />
            </div>
          </div>

          <!-- Botton to send message -->
          <div class="w-auto">
            <div class="bg-purple-900 py-[12px] px-2 rounded-r">
              <button
                @click="send"
                :disabled="loading === true ? true : false"
                class="flex justify-center items-center gap-1"
              >
                <feather-icon
                  icon="SendIcon"
                  class="mx-auto text-white h- w-6"
                />
                <div class="text-center" v-if="loading === true">
                  <b-spinner variant="primary" label="Text Centered" />
                </div>

                <span v-else class="text-white font-[900]">Envoyer</span>
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BAvatar,
  BFormInput,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from "vue-ripple-directive";
import URL from "@/views/request";
import axios from "axios";

export default {
  components: {
    axios,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormFile,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BSpinner,
    ToastificationContent
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      tickets: "",
      ticket_recent: "",

      message: "",
      echanges: "",

      ticketId: "",
      userId: "",
      // reload:"",
      file: [],
      loading: false,
    };
  },

  async mounted() {
    document.title = "Ediqia - Echange";

    this.ticketId = this.$route.params.id;

    await axios.get(URL.USER_TICKET + `/${this.ticketId}`).then((response) => {
      console.log(response.data);
      this.tickets = response.data.ticket_en_cour;
      this.ticket_recent = response.data.ticket_recent;
      this.userId = response.data[0];
    });

    // this.tickets = JSON.parse(localStorage.getItem("ticketEnCour"));

    // this.ticket_recent = JSON.parse(localStorage.getItem("ticketRecent"));

    // console.log(this.tickets, this.ticket_recent);
  },

  methods: {
    content(data) {
      // console.log(data.echanges);
      // localStorage.setItem("message", JSON.stringify(data.echanges));

      //recuperation des messages du tickets
      // this.message = JSON.parse(localStorage.getItem("message"));
      this.echanges = data.echanges;
    },

    content_recent(item) {
      // console.log(item.echanges);
      // localStorage.setItem("message_recent", JSON.stringify(item.echanges));

      //recuperation des messages du tickets
      // this.message = JSON.parse(localStorage.getItem("message"));
      // this.message = JSON.parse(localStorage.getItem("message_recent"));
      this.echanges = item.echanges;

      // console.log(this.message);
    },

    processFile(event) {
      this.file = event.target.files;

      if (event.target.files.length !== 0) {
        console.log(this.file);
      }
    },

    //function send

     success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon:'CheckIcon',
          text: 'Message envoyé avec success',
          variant:'success',
        },
      })
    },
 

    async send() {
      try {
        //form data
        const newFormdata = new FormData();
        newFormdata.append("description", this.message);
        newFormdata.append("ticket_id", this.ticketId);
        newFormdata.append("received_user", this.userId);
        for (let i = 0; i < this.file.length; i++) {
          newFormdata.append("images[]", this.file[i]);
        }

        const data = {
          description: this.message,
          ticket_id: this.ticketId,
          received_user: this.userId,
        };
        if (this.message) {
          this.loading = true;
          await axios.post(URL.SENDMESSAGE, newFormdata).then((response) => {
            if (response.data) {
              ///
              this.success()
              this.loading = false;
              this.message = "";
              this.file = "";
              this.echanges.push(response.data.message);
            }
          });
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
